import {useState} from "react";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {useChattingContext} from '~/pages/message/contexts/ChattingContext.tsx';
import Avatar from '~/components/Avatar';
import Container from "~/components/Container.tsx";
import {IChattingMessage} from "~kint/models/Chatting.ts";
import Typography from "~/components/Typography.tsx";
import Box from '~/components/Box';
import IconButton from "~/components/IconButton.tsx";
import Divider from '~/components/Divider';
import ImageList from "~/components/ImageList.tsx";
import Button from "~/components/Button.tsx";
import useKintStore from "~kint/store";

export type MessageProps = {
  mine?: boolean,
  message: IChattingMessage,
}

export default function Message(props: MessageProps) {
  const {mine, message} = props;
  const {language} = useKintStore((state) => ({language: state.language}));
  const [translate, setTranslate] = useState(false);
  const {setReplyTo} = useChattingContext();
  const handleClickTranslate = () => {
    setTranslate((prev) => !prev);
  }
  const handleCLickReply = () => {
    setReplyTo(message);
  }

  const onScrollMessage = (messageId: string) => {
    const msgEl = document.getElementById(messageId)
    if (!msgEl) return
    msgEl.scrollIntoView({behavior: "auto", block: "center"})
    msgEl.style.animation = "vibration 0.5s"
    setTimeout(() => {
      msgEl.style.animation = ""
    }, 600)
  }

  const isGuide = message.sender.type?.toLowerCase() === 'guide';
  const actionPath = detectActionPath(message.text);
  return (
    <Container
      py={'8px'}
    >
      {
        !mine
          ? <Container
            py={'10px'}
            px={'20px'}
            stackProps={{
              row: true,
              gap: '8px',
              reverse: mine,
              alignItems: 'center'
            }}
          >
            {
              message.sender.type?.toLowerCase() === 'guide'
                ?
                <Avatar
                  variant={'rounded'}
                  sx={(theme) => ({
                    width: '24px',
                    height: '24px',
                    borderRadius: '4px',
                    ...theme.typography.FootNote_B
                  })}
                >
                  GD
                </Avatar>
                : null
            }
            <Typography
              flex={1}
              textAlign={mine ? 'right' : 'left'}
              variant={'FootNote_B'}
              textOverflow={'ellipsis'}
              color={'typography.secondary'}
            >
              {message.sender.nameEn ?? message.sender.name}
            </Typography>
          </Container>
          : null}
      <Container
        pl={mine ? '58px' : '20px'}
        pr={mine ? '20px' : '58px'}
        sx={{
          display: 'flex',
          justifyContent: mine ? 'flex-end' : 'flex-start'
        }}
        stackProps={{
          // justifyContent:'flex-start'
        }}
      >

        <Box
          id={message.id}
          position={'relative'}
          sx={{
            '& button.reply': {
              display: 'none',
            },
            '&:hover button.reply': {
              display: 'inline-block'
            }
          }}
        >
          {
            (message.text || message.reply) &&
              <Box
                  sx={(theme) => ({
                    backgroundColor:
                      mine ? theme.palette.primary.main
                        : isGuide ? theme.palette.secondary.main
                          : theme.palette.secondary.light,
                    color: mine ? theme.palette.primary.contrastText
                      : isGuide ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.contrastText,
                    borderRadius: '12px',
                    borderTopLeftRadius: mine ? '12px' : 0,
                    borderTopRightRadius: mine ? 0 : '12px'
                  })}
                  py={'10px'}
                  px={'14px'}
              >

                {message.reply && (
                  <>
                    <Box
                      component={'div'}
                      pb={'8px'}
                      sx={{background: 'transparent !important'}}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (message?.reply) {
                          onScrollMessage(message.reply.id)
                        }
                      }}
                    >
                      <Typography variant={'Caption'} lineHeight={1}>
                        {message.reply.sender.name}
                      </Typography>
                      <Typography variant={'FootNote'}
                                  flex={1}
                                  color={'typography.tertiary'}
                                  overflow={'hidden'}
                                  textOverflow={'ellipsis'}
                                  sx={{maxLines: 1, maxWidth: '100%'}}
                                  component={'p'}
                                  whiteSpace={'pre-wrap'}>
                        {((message.reply.files?.length ?? 0) > 0 ? 'image' : removeAction(message.reply.text ?? ''))}
                      </Typography>
                    </Box>
                    <Divider type={'line'}/>
                  </>
                )}
                <Typography variant={'BaseS'} whiteSpace={'pre-wrap'}>

                  {
                    renderLinkText(removeAction(
                      language === 'en'
                        ? message.translation?.en ?? message.text
                        : language === 'tw'
                          ? message.translation?.tw ?? message.text
                          : language === 'cn'
                            ? message.translation?.cn ?? message.text
                            : message.text
                    ))
                  }
                </Typography>
                {
                  actionPath &&
                  <Button fullWidth variant={'contained'} size={'small'} color={'primary'} component={Link} to={actionPath} sx={{mt:'16px', mb:'8px'}}>
                    {checkActionLabel(actionPath)}
                  </Button>
                }
                {
                  message.translation && translate
                    ? (
                      <Box>
                        <Box py={'8px'}>
                          <Divider type={'line'}/>
                        </Box>
                        {
                          <Box sx={{mb: 1.5}}>
                            <Typography variant={'Caption_B'} color={mine ? "white" : "typography.secondary"}>
                              {language === 'en' || null ? 'Original Text' : '原文'}
                              {message.translation.detectedLanguage ? `(${message.translation.detectedLanguage.toUpperCase()})` : ''}
                            </Typography>
                            <Typography variant={'FootNote'}
                                        component={'p'}
                                        whiteSpace={'pre-wrap'}>
                              {message.text}
                            </Typography>
                          </Box>
                        }
                        {
                          message.translation.en
                            ?
                            <Box sx={{mb: 1.5}}>
                              <Typography variant={'Caption_B'} color={mine ? "white" : "typography.secondary"}>
                                English
                              </Typography>
                              <Typography variant={'FootNote'} component={'p'}
                                          whiteSpace={'pre-wrap'}>
                                {removeAction(message.translation.en)}
                              </Typography>
                            </Box>
                            : null
                        }
                        {
                          message.translation.tw
                            ?
                            <Box sx={{mb: 1.5}}>
                              <Typography variant={'Caption_B'} color={mine ? "white" : "typography.secondary"}>
                                繁體中文
                              </Typography>
                              <Typography variant={'FootNote'} component={'p'}
                                          whiteSpace={'pre-wrap'}>
                                {removeAction(message.translation.tw)}
                              </Typography>
                            </Box>
                            : null
                        }
                        {
                          message.translation.cn
                            ?
                            <Box sx={{mb: 0.5}}>
                              <Typography variant={'Caption_B'} color={mine ? "white" : "typography.secondary"}>
                                简体中文
                              </Typography>
                              <Typography variant={'FootNote'} component={'p'}
                                          whiteSpace={'pre-wrap'}>
                                {removeAction(message.translation.cn)}
                              </Typography>
                            </Box>
                            : null
                        }
                      </Box>
                    )
                    : null
                }

              </Box>
          }
          {(message.files && message.files.length > 0) && <ImageList imgUrls={message.files}/>}

          <IconButton
            className={'reply'}
            color={'typography.tertiary'}
            onClick={handleCLickReply}
            sx={{
              position: 'absolute',
              left: mine ? '-20px' : undefined,
              right: !mine ? '-20px' : undefined,
              bottom: 0,
              transform: `scaleX(${mine ? '-1' : '1'})`
            }}
          >
            reply
          </IconButton>
        </Box>
        <Container
          pt={'2px'}
          stackProps={{
            row: true,
            reverse: !mine,
            justifyContent: 'space-between',
            gap: '4px'
          }}
        >
          <Typography
            variant={'Caption'}
            color={'typography.tertiary'}
            textAlign={mine ? 'right' : 'left'}
          >

            {dayjs(message.date.toDate()).format('HH:MM')}
          </Typography>
          <IconButton
            iconProps={{
              width: '16px',
            }}
            color={'typography.tertiary'}
            onClick={handleClickTranslate}
          >
            translate
          </IconButton>
        </Container>

      </Container>
    </Container>
  )

}

function renderLinkText(text: string) {
  const protocolUrlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  const simpleUrlRegex = /(\b(?:(?:https?|ftp|file):\/\/)?[-A-Z0-9+&@#/%?=~_|!:,.;]+\.(?:com|net|org|edu|gov|mil|info|biz|name|museum|asia|jobs|mobi|aero|travel|coop|pro|tv|cc|me|co|io|tech|xyz|club|ai|ph|tw|cn|sg|id|jp|ca|us|eu|vn|kr|fr|de|it|ru|br|au|mx|nl|es|se|no|fi))/ig;

  const urlRegex = text.match(protocolUrlRegex) ? protocolUrlRegex : simpleUrlRegex;

  const parts = text.split(urlRegex);
  return parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a style={{color: 'inherit'}} href={part.startsWith('http') ? part : `https://${part}`} key={index}
         target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part
    )
  );
}

const ACTION_REGEX = /\[:(.+)\]/gi;

function removeAction(text: string) {
  const actionLinkRegexp = new RegExp(ACTION_REGEX);
  return text.replace(actionLinkRegexp, "");
}

function detectActionPath(text: string): null | string {
  const actionLinkRegexp = new RegExp(ACTION_REGEX);
  const result = actionLinkRegexp.exec(text);
  const action = result?.[1];
  if (!action || !action.startsWith('/')) return null;
  return result[1];
}

function checkActionLabel(action:string): string {
  switch (action){
    case "/shop":
      return 'Shop Deals Now'
    default :
      return 'Check Now'
  }
}