import {ReactNode, useEffect} from "react";
import {Navigate, Outlet, ScrollRestoration, useLocation, useNavigate} from "react-router-dom";
import {SnackBarProvider} from "~/contexts/SnackBarContext.tsx";
import useKintStore from "~kint/store";
import {useTranslation} from "react-i18next";

export default function BaseLayout({children, restoreScroll = true, hashPages}: {
  children?: ReactNode,
  restoreScroll?: boolean,
  hashPages?: (hash: string) => ReactNode | undefined
}) {

  const navigate = useNavigate();
  const location = useLocation();

  const {reservationKey, reservation, participant} = useKintStore((state) => ({
    reservation: state.reservation,
    reservationKey: state.reservationKey,
    participant:state.participant,
  }));

  //i18n
  const {i18n} = useTranslation();
  useEffect(() => {
    const language = reservation?.language?.toLowerCase() ?? 'en';
    const mappedLanguage = language === 'chinese' ? 'zht' : language;
    i18n.changeLanguage(mappedLanguage)
        .catch((error) => console.error('Language change error:', error));
  }, [reservation?.language, i18n]);


  //정보가 없는 상태에서 진입 방지
  const isReservationPage = location.pathname.startsWith('/reservation');
  const noReservation = !reservationKey || !reservation;
  const differentReservation = reservation?.agencyCode?.toLowerCase() !== reservationKey?.toLowerCase() && reservation?.id?.toLowerCase() !== reservationKey?.toLowerCase();
  const hasNoTourChat = participant?.reservationId !== reservation?.id;
  const hasNoReservation = noReservation || differentReservation;

  const isNotValidApproach = (hasNoReservation || hasNoTourChat) && !isReservationPage;


  if (isNotValidApproach) {
    navigate('/reservation', {replace:true});
    return null;
  }

  const hasPage = hashPages ? hashPages(location.hash) : undefined;

  return (
      <SnackBarProvider>
        {
          isNotValidApproach
          ?<Navigate to={'/reservation'} replace/>
          : hasPage ?? children ?? <Outlet/>
        }
        {
            restoreScroll && <ScrollRestoration/>
        }
      </SnackBarProvider>
  )
}
