import DOMPurify from "dompurify";
import Box from '~/components/Box';

export default function HTML({children}: { children: string }) {
  const sanitized = DOMPurify.sanitize(children);
  return (
    <Box
      sx={(theme) => ({
        '& h1, h2, h3, h4, h5, h6': {
          display: 'block',
          fontSize: '18px',
          fontWeight: 'Medium',
          marginTop: '8px',
          marginBottom: '8px',
          whiteSpace: 'pre-wrap'
        },
        '& img': {
          borderRadius: '8px',
          marginTop: '8px',
          marginBottom: '8px'
        },
        '& p': {
          display: 'block',
          fontSize: '14px',
          whiteSpace: 'pre-wrap',
          marginBottom:'8px',
          color: theme.palette.typography.secondary
        },
      })}
    >
      <div dangerouslySetInnerHTML={{__html: sanitized}}/>
    </Box>
  )
}