import { useLocation, useNavigate } from 'react-router-dom';
import useKupStore from '~kup/store';
import Container from '~/components/Container.tsx';
import Headline from '~/components/Headline.tsx';
import Page from '~/components/Page.tsx';
import TextField from '~/components/TextField.tsx';
import Button from '~/components/Button.tsx';
import { useState } from 'react';
import Stack from '~/components/Stack.tsx';
import List from '~/components/List.tsx';
import ListItem from '~/components/ListItem.tsx';
import { IIssuedCoupon } from '~kup/models/Coupon.ts';

export default function ShopCouponPage() {
  const { setAppliedCoupon } = useKupStore((state) => ({
    setAppliedCoupon: state.setAppliedCoupon,
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const { usableCoupons } = location.state;
  const [newCouponCode, setNewCouponCode] = useState<string | undefined>(undefined);

  return (
    <Page
      type={'page'}
    >
      <Container
        py={'8px'}
      >
        <Container
          pt={'16px'}
          pb={'20px'}
          px={'20px'}
        >
          <Headline headline={'Coupon'} size={'large'} />
        </Container>
        <Container
          p={'8px'}
          px={'20px'}
        >
          <Stack row gap={1}>
            <Container>
              <TextField
                fullWidth
                placeholder={'Input Coupon Code... '}
                value={newCouponCode}
                onChange={(e) => setNewCouponCode(e.target.value)}
              />
            </Container>
            <Button size="small">Apply</Button>
          </Stack>
        </Container>
        <Container
          py={'8px'}
        >
          <List component={'ol'}>
            {
              usableCoupons.map((issuedCoupon: IIssuedCoupon) => {
                const handleUseCoupon = () => {
                  setAppliedCoupon(issuedCoupon);
                  navigate(-1);
                };
                return (
                  <>
                    <ListItem
                      key={issuedCoupon.id}
                      type={'headline'}
                      headline={issuedCoupon.coupon.name}
                      subHeadline={issuedCoupon.isAvailable? `Discount: ${issuedCoupon.applicableDiscountAmount?.toLocaleString()} KRW`: ''}
                      description={issuedCoupon.coupon.description}
                      caution={issuedCoupon.unavailableReason ?? ''}
                      trail={
                        issuedCoupon.isAvailable &&
                        <Button
                          size="small"
                          color="fill"
                          onClick={handleUseCoupon}
                        >Use It</Button>
                      }
                    />
                  </>
                );
              })
            }
          </List>
        </Container>

      </Container>
    </Page>
  );
}