import {useState} from "react";

import Box from "~/components/Box.tsx";
import Typography from "~/components/Typography.tsx";
import Badge from "~/components/Badge.tsx";
import useDeliveryCutOff from "~/pages/shop/hooks/useDeliveryCutOff.ts";
import useScrollIn from "~/hooks/useScrollIn.ts";


export default function OrderDeliveryTimeLimit() {
  const [showBanner, setShowBanner] = useState(false);
  const {deliveryCutOff} = useDeliveryCutOff();
  const scrollObserverRef =  useScrollIn(()=>setShowBanner(false), ()=>setShowBanner(true), 0 )

  return (
      <>
        <Box ref={scrollObserverRef} sx={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
          <Typography variant={'FootNote_B'} color={'typography.secondary'}>
            Order Now, Get it {deliveryCutOff.deliveryDay.toUpperCase()}
          </Typography>

          <Badge
              color={"primary"}
          >
            {deliveryCutOff.timeUntilCutOff.text}
          </Badge>
        </Box>
        {
            showBanner && <OrderDeliveryTimeLimitBanner/>
        }
      </>
  )
}


function OrderDeliveryTimeLimitBanner() {
  const {deliveryCutOff} = useDeliveryCutOff();
  return (
      <Box
          sx={(theme) => ({
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 900,
            backgroundColor:theme.palette.background.paper
          })}
      >
        <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.palette.primary.light,
              py: '8px',
              gap: '8px',
            })}
        >
          <Typography
              variant={"BaseS_B"}
          >
            Order Now, Get it {deliveryCutOff.deliveryDay.toUpperCase()}
          </Typography>

          <Badge
              color={"primary"}
          >
            {deliveryCutOff.timeUntilCutOff.text}
          </Badge>
        </Box>
      </Box>
  )
}