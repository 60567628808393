import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '~/components/ButtonBase.tsx';

declare global {
  class LatLng {
    constructor(lat: number, lng: number);

    lat(): number;

    lng(): number;
  }

  class Marker {
    constructor(options: MarkerOptions);
  }

  class NMap {
    constructor(element: HTMLElement | null, options: MapOptions);
  }

  interface LogoControlOptions {
    position: Position;
  }

  interface ScaleControlOptions {
    position: Position;
  }

  enum Position {
    TOP_LEFT = 0,
    TOP_CENTER = 1,
    TOP_RIGHT = 2,
    LEFT_TOP = 3,
    LEFT_CENTER = 4,
    LEFT_BOTTOM = 5,
    RIGHT_TOP = 6,
    RIGHT_CENTER = 7,
    RIGHT_BOTTOM = 8,
    BOTTOM_LEFT = 9,
    BOTTOM_CENTER = 10,
    BOTTOM_RIGHT = 11,
  }

  interface MapOptions {
    center: LatLng;
    zoom?: number;
    draggable?: boolean;
    pinchZoom?: boolean;
    scrollWheel?: boolean;
    keyboardShortcuts?: boolean;
    disableDoubleTapZoom?: boolean;
    disableDoubleClickZoom?: boolean;
    disableTwoFingerTapZoom?: boolean;
    logoControlOptions?: LogoControlOptions;
    mapDataControl?: boolean;
    scaleControlOptions?: ScaleControlOptions;
  }

  class Point {
    constructor(x: number, y: number);

    x: number;
    y: number;
  }

  interface MarkerOptions {
    position: LatLng;
    map: NMap;
    icon?: Icon;
    // Additional properties as needed
  }

  interface Icon {
    content?: string;
    anchor?: Point;
    // Additional properties as needed
  }


  interface Window {
    naver: {
      maps: {
        Map: typeof NMap,
        LatLng: typeof LatLng,
        Marker: typeof Marker,
        Point: typeof Point,
        Position: typeof Position
      }
    };
  }
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface MarkerInfo extends LatLng {
  label: string;
}

export type NaverMapProps = {
  placeName: string;
  locale: string;
  zoom?: number;
  center: LatLng;
  centerMarker?: boolean;
  markers?: MarkerInfo[];
  disableTouch?: boolean
  containerStyles?: Record<string, string>;
}

export default function NaverMap(props: NaverMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const [enableTouch, setEnableTouch] = useState<boolean>(false);
  const handleToggleTouch = () => {
    setEnableTouch((prev) => !prev);
  };
  const pinMarker = (map: NMap, markerInfo: MarkerInfo) => {
    const position = new window.naver.maps.LatLng(markerInfo.lat, markerInfo.lng);
    const anchorX = (20 + (markerInfo.label.length * 7)) / 2;
    new window.naver.maps.Marker({
      position,
      map,
      icon: {
        content: `
          <div class="naver-map-custom-marker">
            ${markerInfo.label}
          </div>
        `,
        anchor: new window.naver.maps.Point(anchorX, 35)
      }
    });
  };

  useEffect(() => {
    const initializeMap = () => {
      if (!mapRef.current) return;
      const centerLocation = new window.naver.maps.LatLng(props.center.lat, props.center.lng);
      const mapOptions = {
        center: centerLocation,
        draggable: enableTouch,
        pinchZoom: enableTouch,
        scrollWheel: enableTouch,
        keyboardShortcuts: enableTouch,
        disableDoubleTapZoom: enableTouch,
        disableDoubleClickZoom: enableTouch,
        disableTwoFingerTapZoom: enableTouch,
        zoom: props.zoom ?? 15,
        logoControlOptions: {
          position: window.naver.maps.Position.TOP_LEFT,
        },
        mapDataControl: false,
        scaleControlOptions: {
          position: window.naver.maps.Position.TOP_RIGHT,
        },
      };
      const map = new window.naver.maps.Map(mapRef.current, mapOptions);

      if (props.centerMarker !== false) {
        const centerMarker = {label: props.placeName, lat: props.center.lat, lng: props.center.lng};
        pinMarker(map, centerMarker);
      }
      props.markers?.map((marker) => pinMarker(map, marker));
    };

    const script = document.createElement('script');
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=nkwyd1vrg3&language=${props.locale.split('-')[0]}`;
    script.async = true;
    script.onload = initializeMap;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [enableTouch]);

  return (
    <Box sx={{
      position: 'relative',
    }}>
      <div ref={mapRef} style={props.containerStyles}/>
      {
        (!props.disableTouch) && (
          <ButtonBase
            sx={{
              position: 'absolute',
              width: '100%',
              left: '50%',
              bottom: 0,
              p: '8px',
              transform: 'translateX(-50%)',
              textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white',
              backgroundColor: 'rgba(33,33,33,0.1)',
            }}
            onClick={handleToggleTouch}
          >
            {
              enableTouch ?
                'Disable Interaction'
                : 'Enable Interaction'
            }
          </ButtonBase>
        )
      }
    </Box>
  );
}

