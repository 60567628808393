import dayjs from 'dayjs';
import {Link, useNavigate, useParams} from "react-router-dom";
import {DiscoveryPlaceSimple} from "~kup/models/Discovery.ts";
import Page from '~/components/Page';
import useDiscovery from "~/pages/discovery/hooks/useDiscovery.ts";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import NaverMap from "~/components/NaverMap.tsx";
import ImageView from "~/components/ImageView.tsx";
import Divider from "~/components/Divider.tsx";
import HTML from "~/components/HTML.tsx";
import {useLayoutEffect} from "react";

export default function DiscoveryContentPage() {
  const {discoveryId} = useParams();
  const navigate = useNavigate();
  const {discovery} = useDiscovery(discoveryId ?? '');

  useLayoutEffect(() => {
    if (!discoveryId) return navigate(-1)
  }, [discoveryId]);

  if (!discovery)
    return null;
  return (
    <Page
      type={'page'}
    >
      <DiscoveryContentHeader title={discovery.title}
                              date={dayjs(discovery.updatedAt).format('YYYY.MM.DD')}
                              description={discovery.description}
                              places={discovery.places}
                              images={discovery.images}/>
      <Divider type={'box'}/>
      {
        (discovery.places && discovery.places.length > 0) &&
          <>
            <DiscoveryContentPlaces places={discovery.places}/>
            <Divider type={'box'}/>
          </>
      }
      <DiscoveryContentHTML html={discovery.html}/>
    </Page>
  )
}


type DiscoveryContentHeaderProps = {
  title: string,
  description: string,
  date: string,
  images: string[]
  places?: DiscoveryPlaceSimple[],
}

function DiscoveryContentHeader(props: DiscoveryContentHeaderProps) {
  const {title, date, description, places, images} = props;
  const markers = (places ?? []).map(({latlng}, idx) => ({
    label: idx + 1 + '',
    lat: latlng[0],
    lng: latlng[1]
  }));
  return (
    <Container
    >
      <Container>
        {
          (places && places.length > 0) &&
            <Container
            >
              <NaverMap
                  zoom={15}
                  centerMarker={false}
                  center={{lat: places[0].latlng[0], lng: places[0].latlng[1]}}
                  placeName={title}
                  locale={'en-US'}
                  disableTouch={true}
                  markers={markers}
                  containerStyles={{width: '100%', height: 'calc(100vh - 250px)'}}/>
            </Container>
        }
        {
          (!places || places.length === 0) &&
            <ImageView src={images[0]} width={'100%'}/>
        }
      </Container>
      <Container
        px={'20px'}
        py={'16px'}
      >
        <Typography variant={'TitleM_B'} display={'block'}>
          {title}
        </Typography>
        <Typography variant={'Caption'} color={'text.secondary'}>
          {date}
        </Typography>
      </Container>
      <Container
        px={'20px'}
        py={'8px'}
      >
        <Typography variant={'BaseS'} whiteSpace={'pre-wrap'}>
          {description}
        </Typography>
      </Container>
    </Container>

  )
}

type DiscoveryContentHTMLProps = {
  html: string;
}

function DiscoveryContentHTML(props: DiscoveryContentHTMLProps) {
  return (
    <Container
      py={'16px'}
      px={'20px'}
    >
      <HTML>
        {props.html}
      </HTML>
    </Container>
  )
}

type DiscoveryPlacesProps = {
  places: DiscoveryPlaceSimple[]
}

function DiscoveryContentPlaces(props: DiscoveryPlacesProps) {
  const places = props.places;
  if (places.length === 0) return null;
  return (
    <Container
      py={'16px'}
      stackProps={{
        flexDirection: 'column',
        gap:'12px'
      }}
    >
      {
        places.map((p) => {
          return (
            <DiscoveryContentPlace key={p.id} place={p}/>
          )
        })
      }
    </Container>
  )
}

function DiscoveryContentPlace({place}: { place: DiscoveryPlaceSimple }) {
  const name = place.name;
  const url = place.url;
  const image = place.images[0];
  const description = place.description;
  const priceLevel = place.priceLevel;
  const tags = place.tags;
  return (
    <Container
      px={'20px'}
      stackProps={{
        flexDirection: 'column',
      }}
    >
      <Container
        py={'16px'}
      >
        <Typography variant={'Caption'} color={'text.secondary'}>
          {[new Array(priceLevel ?? 0).fill('$').join('')].concat(tags ?? []).filter(s => !!s).join(' • ')}
        </Typography>
        <Typography variant={'TitleS_B'} display={'block'}>
          {name}
        </Typography>
      </Container>
      {
        image &&
          <ImageView src={image} width={'100%'}/>
      }
      <Container
        pt={'16px'}
      >
        <Typography>
          {description}
        </Typography>
        <Link to={url} style={{textDecoration: 'underline', color: 'inherit'}}>
          <Typography variant={'BaseS_B'}>
            Read More
          </Typography>
        </Link>
      </Container>
    </Container>
  )
}